.gswpts_tables_container.collapse_style {
    @media screen and (max-width: 768px) {

        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
            font-size: 14px;
        }

        tbody {
            display: flex;
            flex-direction: column;
        }

        tbody tr td {
            padding-left: 50%;
            position: relative;
            word-break: break-all;

            &::before {
                content: attr(data-content);
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                padding-left: 10px;
                height: 100%;
                width: 44%;
                overflow-x: scroll;
                white-space: nowrap;
                display: flex;
                align-items: center;
                font-weight: bolder;
            }

            &::after {
                content: "";
                position: absolute;
                height: 100%;
                background: rgba(34, 36, 38, 0.1);
                width: 1px;
                display: block;
                top: 0;
                left: 47%;
            }
        }
    }
}

.gswpts_tables_container.scroll_style {
    @media screen and (max-width: 740px) {

        .gswpts_tables_content table td,
        th {
            font-size: 12px;
        }

        .gswpts_tables_content .bottom_options .pagination.menu {
            font-size: 12px;
        }

        .gswpts_tables_content table th {
            padding-right: 0;
        }

        #create_tables_wrapper>div.dt-buttons span {
            font-size: 10px;
        }

        #create_tables_wrapper>div.dt-buttons span>img {
            width: 32%;
        }

        #create_tables_filter>label>span>input[type="search"] {
            padding: 2px;
        }
    }
}



// Hide feature second scss
.gswpts_tables_container,
.gswpts_tables_container {
    .gswpts_tables {

        // Apply styles for desktop/large screens
        @media screen and (min-width: 541px) {
            .hidden-row {
                display: none;
            }

            .hidden-cell {
                .cell_div {
                    visibility: hidden;
                }
            }
        }

        // Apply styles for mobile and tablet screens
        @media screen and (max-width: 540px) {

            //Row
            .hidden-row-mobile {
                display: none;
            }

            .gswpts_rows.hidden-row-mobile {
                display: none;
            }

            tr.gswpts_rows.hidden-row-mobile {
                display: none;
            }


            // Cell 
            .hidden-cell-mobile {
                .cell_div {
                    visibility: hidden;
                }
            }

            .gswpts_rows td.hidden-cell-mobile .cell_div {
                visibility: hidden;
            }

        }
    }
}